<template>
  <div
    @mousemove="isOnHover = true"
    @mouseleave="isOnHover = false"
    @touchmove="isOnHover = true"
    v-if="!!item"
    class="
      offer-card
      dark-box-shadow
      tw-mx-auto tw-mb-4
      md:tw-mb-12
    "
  >
    <template v-if="!!image">
      <div
        v-show="!isOnHover"
        class="card-background w-100 h-100"
        :style="{ backgroundImage: `url(${image})` }"
      >
        <div class="info-block position-absolute">
          <h3 class="tw-font-poppins-bold">{{ item.name }}, {{item.country}}</h3>
          <div class="counter tw-my-2 tw-font-montserrat-semi-bold">
            {{ tripsCount }} Balloon Trip{{ tripsCount > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <div
        v-show="isOnHover"
        class="card-background w-100 h-100"
        :style="{ backgroundImage: `url(${image})` }"
      >
        <div class="position-absolute discover">
            <v-btn
              class="rounded-pill primary--text tw-font-semibold tw-cursor-pointer"
              @click="goToCityPage"
              >Discover Destination</v-btn
            >
        </div>
        <div class="info-block position-absolute">
          <h3 class="tw-font-poppins-bold">{{ item.name }}, {{item.country}}</h3>
          <div class="counter tw-my-2 tw-font-montserrat-semi-bold">
            {{ tripsCount }} Balloon Trip{{ tripsCount > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
    </template>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import { transformImage } from '@/utils/transformImage';

export default {
  props: {
    item: Object
  },
  data: () => ({
    image: '',
    isOnHover: false,
    cardHover: 'cardHover'
  }),
  computed: {
    tripsCount() {
      return this.item.experiences ? this.item.experiences.length : 0;
    }
  },
  watch: {
    item: function() {
      this.setImage();
    }
  },
  mounted() {
    this.setImage();
  },
  methods: {
    ...mapMutations([ 'setExperience' ]),
    goToCityPage() {
      this.setExperience(this.item);
      //this.$router.push({ path: '/destination/' + this.item.name });
      this.$router.push({
        name: 'DestinationPage',
        params: {
          cityName: this.item.name
        },
        query: { mode: 'Discover' },
      });
    },
    setImage() {
      var highResImage = new Image();
      highResImage.onload = () => {
        this.image = transformImage(this.item.background_image, 504);
      };
      highResImage.src = transformImage(this.item.background_image, 504);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';
@import '~@/assets/styles/grid.scss';

.offer-card {
  width: 19.79vw;
  height: 60vh;
  border-radius: 34px;
  overflow: hidden;
  position: relative;
  margin-bottom: 12px;
  @include respond-to(xs-sm) {
    height: 250px;
  }

  &:hover {
    .card-background {
      box-shadow: inset 0 0 100rem rgba(0, 0, 0, 1);
    }
    .info-block {
      opacity: 1;
    }
  }

  .card-background:hover .discover {
    visibility: visible;
  }

  .card-background {
    background-position: center center;
    background-size: cover;
    transition: opacity 0.3s ease-in-out;
  }

  .card-background .discover {
    visibility: hidden;
    text-align: center;
    left: 15%;
    top: 45%;
  }

  .info-block {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    padding-bottom: 20px;
    padding-left: 15px;
    transition: opacity 0.3s ease-in-out;

    background: linear-gradient(
      360deg,
      #00000099 25%,
      rgba(0, 0, 0, 40%) 60%,
      rgba(0, 0, 0, 30%) 70%,
      rgba(0, 0, 0, 20%) 80%,
      rgba(0, 0, 0, 10%) 90%,
      rgba(0, 0, 0, 5%) 95%,
      rgba(0, 0, 0, 2%) 98%,
      rgba(0, 0, 0, 1%) 99%,
      rgba(0, 0, 0, 0%) 100%
    );
    padding-top: 40px;

    h3 {
      font-size: 1.7vw;
      line-height: 32px;
      margin-bottom: 0;
    }

    .counter {
      font-size: 18px;
    }
  }
}
</style>
<style lang="scss">
.offer-card {
  .v-skeleton-loader {
    height: 100%;
    .v-skeleton-loader__card {
      height: 100% !important;
      .v-skeleton-loader__image {
        height: 80%;
      }
    }
  }
}
</style>
