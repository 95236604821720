<template>
  <MatchMedia v-slot="{ md }">
    <div v-if="!!item">
      <div
        class="offer-card tw-rounded-3xl tw-mx-auto active-element tw-mb-4 app-hover-cursor"
        @click="goToInspirationPage(item)"
      >
        <template v-if="!!image">
          <div
            class="card-background w-100 h-100"
            :style="{ backgroundImage: `url(${image})` }"
          />

          <template v-if="!md">
            <div class="tw-absolute tw-inset-x-5 tw-bottom-5 tw-text-white">
              <h3 class="tw-text-2xl tw-pr-14">{{ item.name }}</h3>
              <div class="tw-flex tw-flex-row tw-items-center tw-mb-4">
                <LikeIcon class="tw-mr-1 tw-text-white tw-w-6 tw-h-6" />
                <div>
                  {{ item.likes }}
                </div>
              </div>
              <div class="tw-flex tw-flex-row tw-items-center">
                <div
                  :style="{
                    'background-image': `url(${item.influencer_data.image})`
                  }"
                  class="tw-rounded-full tw-w-8 tw-h-8 tw-bg-cover tw-shadow-xl tw-border-2 tw-border-white"
                />
                <h4 class="tw-ml-2 tw-text-lg">
                  {{ item.influencer_data.first_name }}
                </h4>
              </div>
            </div>
          </template>

          <template v-if="md">

            <div class="info-block position-absolute">
              <h3 class="tw-mb-2">{{ item.name }}</h3>
              <div class="counter">
                <LikeIcon class="tw-mr-1 tw-text-white tw-w-8 tw-h-8" />
                {{ item.likes }}
              </div>
            </div>
          </template>
        </template>

        <template v-else>
          <v-skeleton-loader class="mx-auto" type="card" />
        </template>
      </div>

      <template v-if="md">
        <div class="tw-relative tw-pb-4 tw-px-4">
          <div class="tw-flex tw-flex-row tw-items-center">
            <div
              :style="{
                'background-image': `url(${item.influencer_data.image})`
              }"
              class="tw-rounded-full tw-w-12 tw-h-12 tw-bg-cover tw-shadow-xl"
            />
            <h4 class="tw-ml-2 tw-mt-1 tw-text-lg">
              {{ item.influencer_data.first_name }}
            </h4>
          </div>
        </div>
      </template>
    </div>
  </MatchMedia>
</template>

<script>
import LikeIcon from '@/assets/landingPage/like.svg';
import { MatchMedia } from 'vue-component-media-queries';
import { transformImage } from '@/utils/transformImage';

export default {
  components: {
    LikeIcon,
    MatchMedia
  },
  props: {
    item: Object
  },
  data: () => ({
    image: null
  }),
  mounted() {
    this.setImage();
  },
  computed: {
    src() {
      return transformImage(this.item.background_image);
    }
  },
  methods: {
    goToInspirationPage(item) {
      this.$router.push({
        name: 'InspirationPage',
        params: {
          inspirationId: item.exp_id
        }
      });
    },
    setImage() {
      var highResImage = new Image();
      highResImage.onload = () => {
        this.image = transformImage(this.item.background_image);
      };
      highResImage.src = transformImage(this.item.background_image);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';
@import '~@/assets/styles/grid.scss';

.offer-card {
  max-width: 328px;
  height: 504px;
  overflow: hidden;
  position: relative;

  @include respond-to(xs-sm) {
    height: 250px;
  }

  &:hover {
    .card-background {
      opacity: 0.5;
    }

    .info-block {
      opacity: 1;
    }
  }

  .card-background {
    background-position: center center;
    background-size: cover;
    transition: opacity 0.3s ease-in-out;
    @include respond-to(xs-sm) {
      box-shadow: 0px 4px 4px 0px #00000059;
      box-shadow: inset 0 0 100rem rgba(50, 50, 50, 0.7);
    }
  }

  .info-block {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    padding: 0 15px 20px 15px;
    transition: opacity 0.3s ease-in-out;

    h3 {
      font-size: 30px;
      line-height: 30px;
    }

    .counter {
      display: flex;
      align-items: flex-end;
      font-size: 18px;
    }
  }
}
</style>
