<template>
  <div class="offers-list">
    <div v-if="!!items.length" fluid>
      <v-row>
        <v-col
          v-for="(item, key) in items"
          cols="3"
          sm="3"
          lg="3"
          xl="3"
          :key="key"
          class="offer-card-col"
        >
          <component
            :is="itemComponentName"
            :item="item"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import DestinationCard from './cards/DestinationCard.vue';
import InfluencerCard from './cards/InfluencerCard.vue';

export default {
  components: {
    DestinationCard,
    InfluencerCard
  },
  props: {
    items: Array,
    itemComponentName: String
  },
  computed: {
    searchedText: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit(value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.offer-card-col {
  padding: 3rem 2.5rem 0rem 0rem !important;
}
</style>
