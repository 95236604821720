<template>
  <div class="hidden-xs-only">
    <div class="tw-mb-24 tw-mt-28 tw-container destination-container">
      <header class="main-heading">
        <div class="line">
          Create your own trip to
          <v-autocomplete
            placeholder="exotic destinations"
            v-model="selectedCity"
            class="drop-down"
            :items="selectItems"
            :menu-props="{ bottom: true, offsetY: true }"
          >
            <template v-slot:append> <ArrowIcon /> </template>
          </v-autocomplete>
        </div>
      </header>
      <div class="tw-flex tw-flex-col tw-flex-wrap">
        <div class="tw-w-full tw-mt-28">
          <offers-landing-container
            :items="offersContainerItems"
            :itemComponentName="activeTab.cardName"
          />
          <div
            class="
              tw-flex tw-flex-row tw-justify-center tw-items-center 
              mt-12
            "
          >
            <button @click="loadMore" class="tw-w-60">
              <div
                class="
                  load-more
                  tw-flex
                  tw-flex-row
                  tw-justify-center
                  tw-h-14
                  tw-items-center
                  tw-font-semibold
                  tw-rounded-full
                  
                "
              >
                <p class>Load more</p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowIcon from '@/assets/landingPage/arrow-bottom.svg';
import { offersTabsNames } from '@/views/landingPage/constants';
import OffersLandingContainer from '@/views/landingPage/landingPageComponents/OffersLandingContainer.vue';
import { mapActions, mapGetters } from 'vuex';
const MOBILE_LOAD_MORE_CARDS = 3;
const DESKTOP_LOAD_MORE_CARDS = 8;

export default {
  inject: [ 'mediaQueries' ],
  components: { ArrowIcon, OffersLandingContainer },
  data: function() {
    return {
      selectedCity: '',
      activeTab: offersTabsNames.destinations,
      searchedDestination: '',
      offersTabsNames,
      cardsToShow: 8,
      currentCity: this.$route.query.city
    };
  },
  mounted: async function() {
    this.cardsToShow = this.mediaQueries.md
      ? DESKTOP_LOAD_MORE_CARDS
      : MOBILE_LOAD_MORE_CARDS;
    await this.loadTopExperiences(1);
  },
  computed: {
    ...mapGetters([
      // 'experiences',
      'topExperiences',
      'topExperiencesLoaded',
      'topInfluencers'
    ]),
    selectItems: function() {
      return this.topExperiences.reduce(
        (acc, item) => [ ...acc, { text: item.name, value: item.name } ],
        []
      );
    },
    offersContainerItems() {
      let items = [];
      items = this.selectedCity.length
        ? this.topExperiences.filter(experience =>
          experience.name
            .toLowerCase()
            .includes(this.selectedCity.toLowerCase())
        )
        : this.topExperiences;
      return items.slice(0, this.cardsToShow);
    }
  },
  methods: {
    ...mapActions([ 'loadTopExperiences' ]),
    loadMore() {
      this.cardsToShow += this.mediaQueries.md ? DESKTOP_LOAD_MORE_CARDS : '';
    }
  },
  watch: {
    citySelectItems(newValue) {
      this.selectedCity = newValue[0];
    },
    selectedCity(newValue) {
      this.topExperiences.filter(experience => experience.name === newValue);
    },
    activeTab: {
      handler: function() {
        this.cardsToShow = this.mediaQueries.md
          ? DESKTOP_LOAD_MORE_CARDS
          : MOBILE_LOAD_MORE_CARDS;
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';
@import '~@/assets/styles/app.scss';

.left-alignment {
  margin-left: 4.75rem !important;
}
.destination-container {
  margin-left: 4.75rem;
}

.container-data {
  height: 1380px;
}

.main-heading {
  display: block;
  line-height: 1;
  color: black;
  font-weight: 400;
  font-family: 'PoppinsSemiBold';
  font-size: 6.25vw;
  letter-spacing: -0.3rem;
  max-width: 85vw;
}

.main-heading > .line {
  max-width: 100%;
}

.load-more {
  color: #d62a53;
  margin: auto;
  background-color: #ffe4ec;
  width: 227px;
  height: 64px;
  border-radius: 36px;
  font-family: 'MontserratBold';
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  letter-spacing: -0.01rem;
}

.text-field::v-deep .v-input__slot {
  background: white !important;
  padding: 20px !important;
  width: 415px;
  height: 85px;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08) !important;
  border-radius: 60px !important;
}

.text-field::v-deep .v-select__selection {
  justify-content: center;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  line-height: 30px;
}

@media (max-width: 442px) and (min-width: 300px) {
  .text-field::v-deep .v-input__slot {
    background: white !important;
    padding: 20px !important;
    width: 300px;
    height: 70px;
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08) !important;
    border-radius: 60px !important;
  }
}

.title {
  font-weight: 700;
  font-family: 'PoppinsSemiBold' !important;
  font-size: 6.5rem !important;
  line-height: 6.5rem;
  vertical-align: top;
  letter-spacing: -0.3rem !important;
  color: black;
  @include respond-to(lg) {
    font-size: 5rem !important;
    line-height: 5rem;
  }
  @include respond-to(md) {
    font-size: 3.5rem !important;
    line-height: 3.5rem;
  }
  @include respond-to(sm) {
    font-size: 2.75rem !important;
    line-height: 3rem;
  }
}

.drop-down {
  font-weight: 400;
  font-family: 'PoppinsSemiBold';
  font-size: 6.25vw;
  letter-spacing: -0.04em;
  --max-width: 75%;
}
.drop-down {
  max-width: var(--max-width);
  height: 1em;
  margin: -3px 0 0.1em 0 !important;
  padding: 0 !important;
}
.drop-down::v-deep .v-input__slot {
  border: none !important;
  margin: 0;
  padding: 0 !important;
}
.drop-down::v-deep .v-input__slot::before,
.drop-down::v-deep .v-input__slot::after {
  border: none !important;
}
.drop-down::v-deep .v-select__selection {
  margin: 0 !important;
}
.drop-down::v-deep .v-text-field__details {
  display: none !important;
  cursor: pointer;
}
.drop-down::v-deep .v-input__append-inner {
  margin: 0 !important;
  padding: 0.65em 0 0 0 !important;
  cursor: pointer;
  width: 20px;
}
.drop-down::v-deep .v-select__selection--comma {
  text-overflow: clip;
}

.drop-down::v-deep input,
.drop-down::v-deep input::placeholder {
  max-height: 1.2em !important;
  font-size: inherit !important;
  line-height: 1 !important;
  letter-spacing: inherit;
  text-decoration: underline;
  color: #d62a53 !important;
}
.drop-down::v-deep input {
  //max-width: 79.5%;
  padding-bottom: 0.2em;
  // @include smaller-than(lg) {
  //   max-width: 80%;
  // }
}
</style>
