export const offersTabsNames = {
  influencers: {
    name: 'INFLUENCERS',
    cardName: 'InfluencerCard',
  },
  destinations: {
    name: 'DESTINATIONS',
    cardName: 'DestinationCard',
  },
};
